import React from 'react';
import classes from '../css/modules/ComingSoon.module.css';


class ComingSoon extends React.Component {

  

  render() {

    return (
      <div className="container">
        <h2>Coming Soon</h2>

        <div className={`${classes.fart}`}>UGH!!!</div>

        <div style={{ border: '1px solid red', padding: 20 }}>
          {/* <iframe width='700' height='900' src='https://live.swytchback.com/swydget/16c963/3?userId=abcdefghijklmnopqrstuvwxyz' title="we shouldn't require this" frameborder='0' allow='autoplay; encrypted-media' allowfullscreen></iframe> */}
        </div>
      </div>
    );
  }
}

export default ComingSoon;
