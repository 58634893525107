import React from 'react';


class About extends React.Component {

  render() {

    return (
      <div className="container">
        <h2>About</h2>
        <div>
          The 256 Band is possibly the greatest cover band ever from the Tri-Valley Area in the East Bay. Maybe the
          greatest in the world.
        </div>
        <div>
          We’ve been playing parties and events throughout the Bay Area since 2005.
        </div>
        <div>
          We formed around the principal of having fun playing the kind of music we love. As a result,
          we cover just about every genre from country to reggae, classic rock to blues and even though we are all dudes,
          we have the ability to sing powerful love ballads with the best of them.
        </div>
        <div>
          We are all East Bay locals with deep ties to the communities we call home.s  
        </div>
      </div>
    );
  }
}

export default About;
