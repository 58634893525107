import React, { Component } from 'react';
import { Figure  } from 'react-bootstrap';
import '../App.css';

class BandThumbnail extends Component {

  render() {

    const { imgUrl } = this.props;
    const { bandName } = this.props;
    const { rowIndex } = this.props;
    const { colIndex } = this.props;
    const { selectionHandler } = this.props;

    return (
      <div onClick={() => selectionHandler(rowIndex, colIndex)}>
        <Figure>
          <Figure.Image
            className="images"
            width={171}
            height={180}
            alt="171x180"
            src={imgUrl}
          />
          <Figure.Caption>
            <h5>{bandName}fasdf</h5>
          </Figure.Caption>
        </Figure>
      </div>
    );
  }
}

export default BandThumbnail;
