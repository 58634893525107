import React from 'react';
import '../App.css';

// function useGig() {
//   const [showDetails, setShowDetails] = useState(false);

//   const toggle = () => {
//     setShowDetails(!showDetails);
//   };

//   return {
//     showDetails,
//     toggle,
//   };
// }

function Gig(props) {

  const showtime = props.showtime ? new Date(props.showtime) : new Date();
  const duration = props.duration ? props.duration : 60;
  const venue = props.venue ? props.venue : "TBA";
  const street = props.street ? props.street : "TBA";
  const city = props.city ? props.city : "TBA";
  const endtime = new Date(showtime.getTime());
  endtime.setMinutes(showtime.getMinutes() + duration);

  const formatTime = (date) => {

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amPm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

    return `${formattedHours}:${minutes.toString().padStart(2, '0')}${amPm}`;
  }
  
  return (
    <div className='tableRow'>
      <div className='showtime'>
        <div className='day_of_week'>
          {showtime.toLocaleString('en-US', { weekday: 'short' })}
        </div>
        <div className='date'>
          {showtime.toLocaleString('en-US', { day: '2-digit' })}
        </div>
        <div className='month_and_year'>
          {showtime.toLocaleString('en-US', { month: 'short' })} {showtime.toLocaleString('en-US', { year: 'numeric' })} 
        </div>  
      </div>
      <div className='venue'>
        <div className='name'>
          {venue}
        </div>
        <div className='address'>
          {street}, {city}
        </div>
      </div>
      <div className='gig_times'>
        {formatTime(showtime)} - {formatTime(endtime)}
        </div>
      
    </div>
  );
}

export default Gig;
