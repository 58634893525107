import React from 'react';
import { Popover, Tooltip, OverlayTrigger, Modal ,Container, Col, Row } from 'react-bootstrap';
import theBand from '../model/the-band.json';
import BandThumbnail from '../components/BandThumbnail';

class Music extends React.Component {

  constructor() {
    super();

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.bandsPerRow = 4;

    this.state = {
      showModal: false,
      selectedRow: 0,
      selectedCol: 0
    }
  }

  openModal(rowIndex, colIndex) {
    this.setState({ showModal: true, selectedRow: rowIndex, selectedCol: colIndex });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  generateSongsPopover(band) {

    var songsDivArray = [];

    for (var i = 0; i < band.songs.length; i++) {
      songsDivArray.push(
        <div key={i}>{band.songs[i]}</div>
      )
    }

    return (
      <Popover positionTop={300} id={band.name} title={band.name}>
        {songsDivArray}
      </Popover>
    );
  }

  makeOpenModal(index) {
    return function() {
      this.openModal(index);
    }
  }

  generateBandRows() {

    var rowCount = parseInt(theBand.music.length / this.bandsPerRow, 10);

    if (theBand.music.length % this.bandsPerRow > 0) {
      rowCount += 1;
    }

    var rows = [];

    for (var i = 0; i < rowCount; i++ ) {

      var bands = [];
      for (var j = 0; j < this.bandsPerRow && (i * this.bandsPerRow + j) < theBand.music.length; j++) {
        bands.push(theBand.music[i * this.bandsPerRow + j]);
      }

      console.log(bands);


      rows.push(
        <Row key={i} style={{padding: 0, margin: 0}}>
        {this.generateBandCols(i, bands)}
        </Row>
      );
    }

    return rows;
  }

  generateBandCols(rowIndex, bands) {

    let imageBoxStyle = {
      marginBottom: 20,
      maxWidth: 400,
      border: 2,
      borderStyle: 'solid',
      borderColor: 'white'
    }
    var bandCols = [];

    for (var i = 0; i < bands.length; i++) {
      var imgUrl = bands[i].image;

      var songDivs = [];

      for (var j = 0; j < bands[i].songs.length; j++) {
        songDivs.push(<div key={j}>{bands[i].songs[j]}</div>);
      }

      console.log(songDivs);

      const tooltip = (
        <Tooltip id="tooltip">{songDivs}</Tooltip>
      );

      var col = (
        <Col key={i} md={3}>
        <OverlayTrigger placement="top" overlay={tooltip}>

          <div key={i} style={imageBoxStyle}>
              <BandThumbnail bandName={bands[i].name} rowIndex={rowIndex} colIndex={i} imgUrl={imgUrl} selectionHandler={this.openModal}></BandThumbnail>
          </div>
          </OverlayTrigger>

        </Col>
      );

      bandCols.push(col);

    }

    return bandCols;
  }

  generateBandModal() {

    var bandIndex = this.state.selectedRow * this.bandsPerRow + this.state.selectedCol;
    var band = theBand.music[bandIndex];

    var songDivs = [];

    for (var i = 0; i < band.songs.length; i++) {
      songDivs.push(<div key={i}>{band.songs[i]}</div>);
    }


    return (
      <Modal show={this.state.showModal} onHide={this.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{band.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {songDivs}
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    let containerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }

    let titleStyle = {
      color: '#ffffff',
      width: '100%',
      textAlign: 'center'
    }

    let instructionsStyle = {
      color: '#ffffff',
      width: '100%',
      padding: 10,
      margin: 5,
      textAlign: 'center'
    }

    console.log(theBand);

    return (
      <div className="container">
        <h2 style={titleStyle}>Music</h2>
        <div style={instructionsStyle}>Tap on a band to see the songs we play.</div>
        <div style={containerStyle}>
          <Container>
          {this.generateBandRows()}
          </Container>
        </div>
        {this.generateBandModal()}
      </div>
    );
  }
}

export default Music;
