import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom'
import Home from "../pages/Home";
import ComingSoon from "../pages/ComingSoon";
import About from "../pages/About";
import Music from "../pages/Music";
import Contact from "../pages/Contact";
import Schedule from '../hooks/Schedule';

class Main extends Component {

  render() {
    return (
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="coming_soon" element={<ComingSoon />} />
          <Route path="music" element={<Music />} />
          <Route path="schedule" element={<Schedule />} />
          <Route path="about" element={<About />} />
          <Route path='/about' component={About}/>
          <Route path='/music' component={Music}/>
          <Route path='/contact' component={Contact}/>
        </Routes>
      </main>
    );
  }
}

export default Main;
