import React, { Component } from 'react';
import '../App.css';
import { Image, Navbar, Nav, Container } from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';
import { AiTwotoneHome } from 'react-icons/ai';


class Header extends Component {

  render() {
    return (
      <header>
        <div className="banner">
          <div className="taglineContainer">
            <div className="headerTitle">
              Highway 256
              <Image
                className="logo"
                src="https://s3.us-west-2.amazonaws.com/resources.the256band.com/assets/images/256.png"
              />
            </div>
            <div className="subtitle">
              Rock, Country, Blues, Reggae AND Funk
            </div>
            <div className="subtitle">
              Call Darrell Garner at 925.487.4075
            </div>  
          </div>
        </div>
        
        <div className='social_stuff'>
          <Navbar>
            <Container>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="/"><AiTwotoneHome /></Nav.Link>
                  <Nav.Link href="https://www.facebook.com/highway256/"><FaFacebookF /></Nav.Link>
                  <Nav.Link href="https://www.instagram.com/highway_256/"><FaInstagram /></Nav.Link>
                  <Nav.Link href="https://www.instagram.com/highway_256/"><FaYoutube /></Nav.Link>
                  <Nav.Link href="/schedule">Gigs</Nav.Link>
                </Nav>
              </Navbar.Collapse>  
            </Container>
          </Navbar>
        </div>
      </header>
    );
  }
}

export default Header;
