import React from 'react';


class Contact extends React.Component {

  render() {

    return (
      <div className="container">
        <h2>Contact</h2>
      </div>
    );
  }
}

export default Contact;
