import React from 'react';
import { Carousel, Image, Figure, Container, Row, Col } from 'react-bootstrap';
import '../App.css';
// import one from '../assets/darrell_blaine_tinsley.jpg';
// import two from '../assets/blaine_dg.jpg';
// import three from '../assets/Kenjie_Landscape-2.jpg';
// import four from '../assets/dg_land.jpg';
// import five from '../assets/dg_land-3.jpg';
// import six from '../assets/blaine_land.jpg';
import theBand from '../model/the-band.json';

class Home extends React.Component {

  constructor() {
    super();

    this.member = theBand["blaine"];

    this.state = {

    }
  }

  generateProfilePics() {
    let imageBoxStyle = {
      marginTop: 40,
      marginBottom:40,
      paddingTop: 10,
      border: 2,
      borderStyle: 'solid',
      borderColor: 'white',
      borderRadius: 10,
      backgroundColor: '#ffffff'
    }
    var divs = [];

    var i = 0;
    for (var key in theBand.members) {
      var imgUrl = theBand.members[key].profile_image;
      divs.push(
        <Col key={i} md={3}>
          <div style={imageBoxStyle}>
            <Figure>
              <Figure.Image
                className="images"
                width={171}
                height={180}
                alt="171x180"
                src={imgUrl}
              />
              <Figure.Caption>
                <h3>{theBand.members[key].first_name}</h3>
                <p>{theBand.members[key].last_name}</p>
              </Figure.Caption>
            </Figure>
          </div>
        </Col>
      )
      i++
    }

    return divs;
  }

  generateVideoBoxes() {
    let imageBoxStyle = {
      marginTop: 40,
      marginBottom:40,
      paddingTop: 20,
      color: '#6c757d',
      border: 2,
      borderStyle: 'solid',
      borderColor: 'white',
      borderRadius: 10,
      backgroundColor: '#ffffff'
    }
    var divs = [];

    // This is where we have a bunch of videos to show.

    var i = 0;
    for (var videoObj of theBand.videos) {
      // var url = videoObj.src;
      divs.push(
        <Col key={i} md={6}>
          <div style={imageBoxStyle}>
            <div style={{marginBottom: 20}}>
              <iframe width="320" height="180" src={videoObj.src} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
            <div>
              <h4>{videoObj.artist}</h4>
              <p>{videoObj.title}</p>
            </div>
          </div>
        </Col>
      )
      i++
    }

    return divs;
  }


  render() {
    let containerStyle = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    }
    
    return (
      <div>
        <div>
          <Carousel>
            <Carousel.Item>
              <Image src={"https://s3.us-west-2.amazonaws.com/resources.the256band.com/assets/images/256-sideboard-band-photo-cropped.jpg"} style={{width: '100%'}} />
              <Carousel.Caption>
                <h3>Tom Petty</h3>
                <p>American Girl, Wont Back Down, The Waiting, Listen to Her Heart</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image src={"https://s3.us-west-2.amazonaws.com/resources.the256band.com/assets/images/highway256-tinsley.jpg"} style={{width: '100%'}} />
              <Carousel.Caption>
                <h3>Carlos Santana</h3>
                <p>Black Magic Woman, Evil Ways, Smooth</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image src={"https://s3.us-west-2.amazonaws.com/resources.the256band.com/assets/images/highway256-tinsley-backstage.jpg"} style={{width: '100%'}} />
              <Carousel.Caption>
                <h3>Eric Clapton</h3>
                <p>Crossroads, Wonderful Tonight, Key to the Highway</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image src={"https://s3.us-west-2.amazonaws.com/resources.the256band.com/assets/images/southern-rock-alligator.jpg"} style={{width: '100%'}} />
              <Carousel.Caption>
                <h3>Southern Rock</h3>
                <p>Lynyrd Skynyrd, Marshall Tucker Band, ZZ Top</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image src={"https://s3.us-west-2.amazonaws.com/resources.the256band.com/assets/images/256-darrell-left-cropped.jpg"} style={{width: '100%'}} />
              <Carousel.Caption>
                <h3>Creedence Clearwater Revival</h3>
                <p>Who Will Stop the Rain, Born on the Bayou, Fortunate Son</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image src={"https://s3.us-west-2.amazonaws.com/resources.the256band.com/assets/images/highway256-tinsley-guitar.jpg"} style={{width: '100%'}} />
              <Carousel.Caption>
                <h3>Stevie Ray Vaughan</h3>
                <p>Texas Flood, Pride and Joy, Cold Shot, The Sky is Crying</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
        <div style={containerStyle}>
          <Container>
            <Row>
              {this.generateVideoBoxes()}
            </Row>
            <Row>
              {this.generateProfilePics()}
            </Row>
          </Container>
        </div>
      </div>

    );
  }
}

export default Home;
