import React from 'react';
import '../App.css';
import Gig from './Gig';
import model from '../model/the-band.json';


const generateGigs = (gigs) => {

  const gigsArray = [];

  for (const gigIndex in gigs) {

    gigsArray.push(
      <Gig 
        key={gigIndex}
        showtime={gigs[gigIndex].showtime}
        duration={gigs[gigIndex].duration}
        venue={gigs[gigIndex].venue}
        title={gigs[gigIndex].title}
        street={gigs[gigIndex].street}
        city={gigs[gigIndex].city}
        state={gigs[gigIndex].state}
        event_url={gigs[gigIndex].event_url}
      />
    )
  }

  return gigsArray;
}


function Schedule() {

  let gigsArray = model.gigs;

  gigsArray = gigsArray.sort(
    (a, b) => {
      const aDate = new Date(a.showtime);
      const bDate = new Date(b.showtime);
      return aDate - bDate
    }
  );

  let upcomingGigs = [];
  let pastGigs = [];
  const currentDate = new Date();

  for (const gig of gigsArray) {

    const showtime = new Date(gig.showtime);

    if (showtime < currentDate) {
      pastGigs.push(gig);
    }
    else {
      upcomingGigs.push(gig);
    }
  }

  upcomingGigs = upcomingGigs.sort(
    (a, b) => {
      const aDate = new Date(a.showtime);
      const bDate = new Date(b.showtime);
      return aDate - bDate
    }
  );

  pastGigs = pastGigs.sort(
    (a, b) => {
      const aDate = new Date(a.showtime);
      const bDate = new Date(b.showtime);
      return bDate - aDate
    }
  );

  return (
    <div className='scheduleContainer'>
      <div className="title">
        Upcoming Gigs
      </div>
      <div className='schedule'>
        {generateGigs(upcomingGigs)}
      </div>
      <div className='title'>
        Past Gigs
      </div>
      <div className='schedule'>
        {generateGigs(pastGigs)}
      </div>
    </div>
  );
}

export default Schedule;
